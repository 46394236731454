<template>
  <div class="box">
    <!-- 概览标题 -->
    <div class="overview">
      <img src="@/assets/img/line.png" alt="" />
      <span>概览</span>
    </div>
    <!-- 选择任务按钮 -->
    <el-button type="primary" class="btn" @click="funSelectSchool"
      >+选择采集任务</el-button
    >

    <!-- 采集任务列表盒子 -->
    <div style="display: flex; flex-direction: column; height: calc(100vh - 320px)">
      <el-table
        border
        :data="SpareListXP"
        default-expand-all
        style="width: 100%; text-align: center"
        height="100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        @selection-change="handleSelectionMerge"
      >
        <el-table-column prop="title" label="任务名称" width="320"> </el-table-column>
        <el-table-column align="center" prop="photographer" label="摄影师" width="180">
        </el-table-column>
        <el-table-column align="center" prop="count" label="采集人数" width="180">
        </el-table-column>
        <el-table-column
          align="center"
          prop="beginTime"
          :formatter="columnDateFormat"
          label="采集时间"
          width="220"
        >
        </el-table-column>
        <el-table-column align="center" prop="address" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="handleInformation(scope.row)">
              <span v-if="scope.row.judgeIsSplit === 1">修改上传信息</span>
              <span v-if="scope.row.judgeIsSplit === 0">填写上传信息</span>
            </el-button>
            <el-button
              size="small"
              type="danger"
              @click.native.prevent="handleDelete(scope.$index, SpareListXP)"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="text-align: center" class="mg-top-15">
      <el-button
        type="primary"
        size="small"
        @click="merge"
        :loading="loading"
        :disabled="loading"
        v-if="SelectSchoolButton"
        >创建上传批次</el-button
      >
    </div>
    <!-- 新增弹窗 -->
    <div style="height: 100%">
      <el-dialog
        title="选择订单"
        :visible.sync="dialogVisible"
        width="1200px"
        class="ERER"
      >
        <!-- 搜索部分 -->
        <div style="display: flex; flex-wrap: wrap">
          <div class="condition-item">
            <label class="fn-14">任务名称</label>
            <el-input
              clearable
              v-model="searchTaskName"
              placeholder="请输入任务名称"
              autocomplete="off"
              size="small"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">采集时间</label>
            <el-date-picker
              v-model="searchBeginCollect"
              type="date"
              placeholder="选择开始时间"
              autocomplete="off"
              size="small"
              style="width: 160px"
            >
            </el-date-picker>
            <label class="fn-14" style="padding-left: 10px">-</label>
            <el-date-picker
              v-model="searchEndCollect"
              type="date"
              placeholder="选择结束时间"
              autocomplete="off"
              size="small"
              style="width: 160px"
            >
            </el-date-picker>
          </div>

          <div class="condition-item">
            <label class="fn-14">摄影师</label>
            <el-input
              clearable
              v-model="searchPhotographer"
              placeholder="请输入摄影师名称"
              autocomplete="off"
              size="small"
              style="width: 120px"
            ></el-input>
          </div>
          <div class="condition-item">
            <el-button
              type="primary"
              size="small"
              style="width: 85px"
              @click="handleSearch"
              >搜索</el-button
            >
          </div>
        </div>

        <!-- 采集列表盒子 -->
        <div>
          <el-table
            border
            :data="choiceList"
            style="width: 100%; text-align: center; overflow: auto"
            height="400"
            :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
            v-loading="tableTaskAndLoading"
            @selection-change="handleSelectiSchool"
          >
            <template #empty>
              <p>
                {{ tableTaskAndLoading == true ? "数据加载中" : "暂无数据" }}
              </p>
            </template>
            <el-table-column align="center" type="selection" width="50">
            </el-table-column>
            <el-table-column prop="title" label="任务名称" width="320"> </el-table-column>
            <el-table-column
              align="center"
              prop="photographer"
              label="摄影师"
              width="220"
            >
            </el-table-column>
            <el-table-column align="center" prop="count" label="采集人数" width="220">
            </el-table-column>
            <el-table-column
              align="center"
              prop="beginTime"
              :formatter="columnDateFormat"
              label="采集时间"
            >
            </el-table-column>
          </el-table>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button type="text">共{{ PeopleNum }}人</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handlePreservation">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 上传信息弹窗 -->
    <div style="height: 80%">
      <el-dialog
        title="
      上传信息确认  
      "
        :visible.sync="informationVisible"
        width="900px"
      >
        <el-form
          ref="iSplitData"
          :model="iSplitData"
          label-width="120px"
          :rules="informationRules"
        >
          <el-form-item label="学校名称" prop="schoolName">
            <el-input
              disabled
              v-model="iSplitData.schoolName"
              type="text"
              placeholder="请输入学校名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="学校代码" prop="schoolCode">
            <el-input
              disabled
              type="text"
              v-model="iSplitData.schoolCode"
              placeholder="请输入学校编码"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否需要分装">
            <el-radio-group v-model="iSplitData.isSplit" @change="handleIsSplit">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="联系人" prop="centerContact">
            <el-input
              type="text"
              v-model="iSplitData.centerContact"
              placeholder="请输入联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="centerPhone">
            <el-input
              type="text"
              v-model="iSplitData.centerPhone"
              placeholder="请输入联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮政编码" prop="postalCode">
            <el-input
              type="text"
              v-model="iSplitData.postalCode"
              placeholder="请输入邮政编码"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮寄地址" prop="centerAddress">
            <el-input
              type="text"
              v-model="iSplitData.centerAddress"
              placeholder="请输入邮寄地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱" prop="centerEmail">
            <el-input
              type="text"
              v-model="iSplitData.centerEmail"
              placeholder="请输入电子邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item label="摄影师姓名" prop="photographer">
            <el-input
              type="text"
              v-model="iSplitData.photographer"
              placeholder="请输入摄影师姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="拍摄时间" prop="beginTime">
            <el-input
              type="text"
              disabled
              :value="formDateFormat(iSplitData.beginTime)"
              placeholder="请输入拍摄时间"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="拍摄人数" prop="count">
            <el-input
              disabled
              type="text"
              v-model="iSplitData.count"
              placeholder="请输入拍摄人数"
            ></el-input>
          </el-form-item>
          <el-form-item label="分社名称" prop="centerName">
            <el-input
              disabled
              type="text"
              v-model="iSplitData.centerName"
              placeholder="请输入分社名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="拍摄卡号" prop="centerNo">
            <el-input
              disabled
              type="text"
              v-model="iSplitData.centerNo"
              placeholder="请输入卡号"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否加急" prop="isWorry">
            <el-radio-group v-model="iSplitData.isWorry" disabled>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="上传备注" prop="remarks">
            <el-input
              v-model="iSplitData.remarks"
              type="textarea"
              autosize
              placeholder="请填写其它上传需求"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleCancel">取 消</el-button>
          <el-button type="primary" @click="handleConfirm" style="margin-left: 20px"
            >保 存
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  queryTaskAndCount,
  batchAdd,
  getContacts,
  schoolCollectsInformation,
} from "@/api/batch";
import { dateFormat } from "@/utils/date";
import { queryPageButton } from "@/api/permission";

export default {
  name: "batchOrderMgt",
  data() {
    var telres = /^1[0-9]{10}$/;
    const validateIsSplit = (rule, value, callback) => {
      if (value != 0 && value != 1) {
        callback(new Error("请选择是否分装"));
      } else {
        callback();
      }
    };
    const validateContactName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("联系人不能为空"));
      } else {
        callback();
      }
    };
    const validateContactPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空"));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的11位数"));
      } else {
        if (telres.test(value) == false) {
          callback(new Error("手机号格式不正确"));
        } else {
          callback();
        }
      }
    };
    const validateContactAddress = (rule, value, callback) => {
      if (!value) {
        callback(new Error("邮寄地址不能为空"));
      } else {
        callback();
      }
    };
    const validatePostalCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("邮寄编码不能为空"));
      } else {
        callback();
      }
    };
    const validatePhotographer = (rule, value, callback) => {
      if (!value) {
        callback(new Error("摄影师不能为空"));
      } else {
        callback();
      }
    };
    const validateIsWorry = (rule, value, callback) => {
      if (value != 0 && value != 1) {
        callback(new Error("请选择是否加急"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      tableTaskAndLoading: false,
      informationVisible: false,
      tableSelection: [],
      tableSelectionMerge: [],
      // 选择采集搜索
      searchTaskName: "",
      searchBeginCollect: null,
      searchEndCollect: null,
      searchPhotographer: "",
      choiceList: [],
      SpareListXP: [],
      iSplitData: { isSplit: 0 },
      dataForm: {},
      PeopleNum: 0,
      informationRules: {
        isSplit: [
          {
            required: true,
            trigger: "blur",
            validator: validateIsSplit,
          },
        ],
        centerContact: [
          {
            required: true,
            trigger: "blur",
            validator: validateContactName,
          },
        ],
        centerPhone: [
          {
            required: true,
            trigger: "blur",
            validator: validateContactPhone,
          },
        ],
        postalCode: [
          {
            required: true,
            trigger: "blur",
            validator: validatePostalCode,
          },
        ],
        centerAddress: [
          {
            required: true,
            trigger: "blur",
            validator: validateContactAddress,
          },
        ],
        photographer: [
          {
            required: true,
            trigger: "blur",
            validator: validatePhotographer,
          },
        ],
        isWorry: [
          {
            required: true,
            trigger: "blur",
            validator: validateIsWorry,
          },
        ],
      },
      loading: false,
      schoolCollectsId: null,
      schoolCollectsType: null,
      schoolCollectsData: {},
      schoolPhotographer: null,

      // 权限按钮
      SelectSchoolButton: false,
    };
  },
  mounted() {
    this.queryButton();
  },

  methods: {
    queryButton() {
      const data = 118;
      queryPageButton(data).then((resp) => {
        // console.log(resp);
        for (const button of resp.data) {
          if (button.code === "SelectSchoolButton") {
            this.SelectSchoolButton = true;
          }
        }
      });
    },
    /**
     * 选择采集任务
     */
    funSelectSchool() {
      this.searchTaskName = "";
      (this.searchBeginCollect = null),
        (this.searchEndCollect = null),
        (this.searchSchoolName = ""),
        (this.searchPhotographer = ""),
        (this.dialogVisible = true),
        (this.choiceList = []),
        (this.tableTaskAndLoading = true),
        this.query();
    },
    query() {
      //选择采集任务列表
      const postData = {};
      postData.taskName = this.searchTaskName;
      postData.beginCollect = this.formDateFormat(this.searchBeginCollect);
      postData.endCollect = this.formDateFormat(this.searchEndCollect);
      postData.photographer = this.searchPhotographer;

      //选择采集任务列表
      queryTaskAndCount(postData).then((resp) => {
        this.tableTaskAndLoading = false;
        if (resp.code == 0) {
          let datas = resp.data;
          this.choiceList = datas;
        }
      });
    },
    /**
     * 时间格式化
     *
     * **/
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    //搜索
    handleSearch() {
      this.query();
    },
    // 创建合并选择
    handleSelectionMerge(val) {
      this.tableSelectionMerge = val;
    },
    // 弹窗选择
    handleSelectiSchool(val) {
      this.tableSelection = val;
      let datas = this.tableSelection;
      let num = 0;
      datas
        .filter((item) => item.count)
        .forEach((item) => {
          num += item.count;
        });
      if (num == 0) {
        this.PeopleNum = 0;
      } else {
        this.PeopleNum = num;
      }
    },
    // 弹窗选择确定
    handlePreservation() {
      const tableSelection = JSON.parse(JSON.stringify(this.tableSelection));
      if (tableSelection === null || tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的任务不能为空",
          type: "warning",
        });
        return;
      }
      let SparedataXP = [];
      if (this.SpareListXP.length == 0) {
        SparedataXP = tableSelection;
        for (const item of SparedataXP) {
          item.judgeIsSplit = 0;
          this.SpareListXP.push(item);
        }
      } else {
        for (const item of tableSelection) {
          const json = this.SpareListXP.filter((o) => o.id == item.id);
          if (!json.length) {
            item.judgeIsSplit = 0;
            this.SpareListXP.push(item);
          }
        }
      }

      this.dialogVisible = false;
    },
    // 创建合并

    merge() {
      let taskInfoConfirms = [];
      let Num = this.SpareListXP.length;
      let json = JSON.parse(JSON.stringify(this.SpareListXP));
      let judgeIsSplit = [];
      json.map((item) => {
        // console.log(item);
        judgeIsSplit.push(item.judgeIsSplit);
        taskInfoConfirms.push({
          taskId: item.id,
          isSplit: item.isSplit,
          contactName: item.centerContact,
          contactPhone: item.centerPhone,
          contactAddress: item.centerAddress,
          contactEmail: item.centerEmail,
          isWorry: item.isWorry,
          remarks: item.remarks,
          photographer: item.photographer,
          collectType: item.collectType,
          postalCode: item.postalCode,
        });
      });
      let dataNum = judgeIsSplit.filter((item) => item == 1);
      if (Num != dataNum.length) {
        this.$notify({
          title: "警告",
          message: "请核实所有上传信息已填写,再进行上传",
          type: "warning",
        });
      } else {
        this.loading = true;
        let postData = {
          taskInfoConfirms,
        };
        batchAdd(postData)
          .then((resp) => {
            if (resp.code == 0) {
              this.$notify({
                title: "温馨提示",
                message: resp.message,
                type: "success",
              });
              this.loading = false;
              this.tableSelectionMerge = this.SpareListXP = [];
            } else {
              this.$notify({
                title: "温馨提示",
                message: resp.message,
                type: "error",
              });
              this.loading = false;
            }
          })
          .catch((error) => {
            this.$notify({
              title: "警告",
              message: error,
              type: "error",
            });
            this.loading = false;
          });
      }
    },

    // 上传信息确认查看
    handleInformation(datas) {
      this.schoolCollectsId = datas.id;
      this.schoolCollectsType = datas.collectType;
      this.iSplitData.isSplit = 0;
      let postdata = {
        id: this.schoolCollectsId,
        collectType: this.schoolCollectsType,
      };

      let rows = JSON.parse(JSON.stringify(datas));
      if (rows.judgeIsSplit == 1) {
        this.iSplitData = rows;
        this.iSplitData.centerContact = this.iSplitData.centerContact;
        this.iSplitData.centerPhone = this.iSplitData.centerPhone;
        this.iSplitData.centerAddress = this.iSplitData.centerAddress;
        this.informationVisible = true;
      } else {
        let iSplitData = JSON.parse(JSON.stringify(this.iSplitData));
        schoolCollectsInformation(postdata).then((resp) => {
          if (resp.code == 0) {
            this.schoolCollectsData = resp.data;
            iSplitData.centerAddress = this.schoolCollectsData.contactAddress;
            iSplitData.centerContact = this.schoolCollectsData.contactName;
            iSplitData.centerPhone = this.schoolCollectsData.contactPhone;
            iSplitData.postalCode = this.schoolCollectsData.postalCode;
            iSplitData.centerEmail = this.schoolCollectsData.contactEmail;
            iSplitData.isWorry = this.schoolCollectsData.isWorry;
            iSplitData.remarks = this.schoolCollectsData.remarks;
            iSplitData.collectType = this.schoolCollectsData.collectType;
          }
        });
        let isSplit = 1;
        // console.log(rows);
        iSplitData.schoolName = rows.schoolName;
        iSplitData.schoolCode = rows.schoolCode;
        iSplitData.count = rows.count;
        this.schoolPhotographer = rows.photographer;
        iSplitData.photographer = this.schoolPhotographer;
        iSplitData.beginTime = rows.beginTime;
        iSplitData.id = rows.id;
        getContacts(isSplit).then((resp) => {
          if (resp.code == 0) {
            this.dataForm = resp.data;
            iSplitData.centerName = this.dataForm.centerName;
            iSplitData.centerNo = this.dataForm.centerNo;
            iSplitData.Address = this.dataForm.centerAddress;
            this.iSplitData = iSplitData;
            this.informationVisible = true;
          }
        });
      }
    },
    // 上传信息保存
    handleConfirm() {
      this.$refs.iSplitData.validate((valid) => {
        if (valid) {
          let iSplitData = JSON.parse(JSON.stringify(this.iSplitData));
          let listData = this.SpareListXP.filter(
            (item) =>
              item.id == iSplitData.id && item.collectType == iSplitData.collectType
          );
          listData[0].isSplit = iSplitData.isSplit;
          listData[0].isWorry = iSplitData.isWorry;
          listData[0].contactName = iSplitData.centerName;
          listData[0].centerPhone = iSplitData.centerPhone;
          listData[0].centerAddress = iSplitData.centerAddress;
          listData[0].centerEmail = iSplitData.centerEmail;
          listData[0].remarks = iSplitData.remarks;
          listData[0].postalCode = iSplitData.postalCode;
          listData[0].photographer = iSplitData.photographer;
          listData[0].centerContact = iSplitData.centerContact;
          listData[0].judgeIsSplit = 1;
          this.iSplitData = iSplitData;
          console.info(listData);
        } else {
          return false;
        }

        this.informationVisible = false;
      });
    },
    // 取消
    handleCancel() {
      this.iSplitData.photographer = null;
      this.iSplitData.centerContact = null;
      this.iSplitData.centerPhone = null;
      this.iSplitData.centerEmail = null;
      this.iSplitData.isWorry = null;
      this.iSplitData.remarks = null;
      this.informationVisible = false;
    },
    // 是否分装
    handleIsSplit(dataIsSplit) {
      let iSplitData = JSON.parse(JSON.stringify(this.iSplitData));
      if (dataIsSplit == 1) {
        iSplitData.photographer = this.dataForm.photographer;
        iSplitData.centerContact = this.dataForm.centerContact;
        iSplitData.centerPhone = this.dataForm.centerPhone;
        iSplitData.centerEmail = this.dataForm.centerEmail;
        iSplitData.centerAddress = this.dataForm.centerAddress;
        iSplitData.postalCode = this.dataForm.postalCode;
      } else if (dataIsSplit == 0) {
        let postdata = {
          id: this.schoolCollectsId,
          collectType: this.schoolCollectsType,
        };

        schoolCollectsInformation(postdata).then((resp) => {
          if (resp.code == 0) {
            iSplitData.photographer = this.schoolPhotographer;
            this.schoolCollectsData = resp.data;
            iSplitData.centerAddress = this.schoolCollectsData.contactAddress;
            iSplitData.centerContact = this.schoolCollectsData.contactName;
            iSplitData.centerPhone = this.schoolCollectsData.contactPhone;
            iSplitData.postalCode = this.schoolCollectsData.postalCode;
            iSplitData.centerEmail = this.schoolCollectsData.contactEmail;
          }
        });
        iSplitData.isSplit = 0;
      }
      this.iSplitData = iSplitData;
    },

    // 移除
    handleDelete(index, rows) {
      rows.splice(index, 1);
    },

    formDateTimeBegin(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd 00:00:00", new Date(dateValue));
      }
    },
    formDateTimeEnd(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd 23:59:59", new Date(dateValue));
      }
    },
    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
  },
};
</script>

<style scoped>
.el-checkbox__inner {
  border-radius: 20px !important;
}
/* 概览标题 */
.box div.overview {
  display: flex;
  align-items: center;
  width: 100px;
  height: 30px;
  font-size: 14px;
}
.box div.overview img {
  width: 20%;
}
/* 选择任务按钮 */
.box .btn {
  margin: 15px 0px 10px 8px;
  height: 35px;
  width: 160px;
  line-height: 10px;
}
/* 移除按钮 */
.d-btn {
  width: 85px;
  height: 30px;
  text-align: center;
  line-height: 5px;
}
/* 列表盒子 */
.box div.plan-box {
  margin: 10px;
}
</style>
